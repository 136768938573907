.error {
  padding-top: 170px;
  padding-bottom: 60px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #777793;
  margin-bottom: 33px;
}

.text {
  font-family: Ubuntu, sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 43px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  color: #111035;
  margin-bottom: 68px;
}
@media (max-width: 768px) {
  .text {
    font-size: 23px;
    margin-bottom: 103px;
  }
}
